import config from "./config/config";

const IS_BOOTSTRAPPED = 'bootstrapped';

const isValidUUID = (uuidValue) => {
  return validUUIDRegEx.test(uuidValue);
}

const minifyJsAndHTML = function (code) {
  return code
    .replace(/>[\r\n ]+</g, "><")
    .replace(/(<.*?>)|\s+/g, (_, a) => (a ? a : " "))
    .trim();
};

const validUUIDRegEx = /^[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$/i;

function injectCarouselIframe() {
  const containers = document.getElementsByClassName('videopeel-widget-container');

  if (!containers || !containers.length) {
    console.log('No container found.');
    return;
  }

  Array.from(containers).forEach(container => {
    const pluginId = container.getAttribute('data-plugin-id');
    const organizationId = container.getAttribute('data-vp-organization-id');
    const hasContainerBeenBootstrapped = container.getAttribute('data-vp-status');

    if (hasContainerBeenBootstrapped) return;

    if(!isValidUUID(pluginId)) {
      console.error(`VideoPeel detected an invalid pluginId: ${pluginId}. Please double check your installation.`);
      container.innerHTML = minifyJsAndHTML(`
      <style>
        .videopeel-error-message {
          overflow: hidden;
          width: 350px;
          padding: 40px 20px;
          margin: 20px auto;
          border: 1px solid #ccc;
          border-radius: 3px;
          background-color:rgba(255, 255, 255, 0.5);
        }
        .videopeel-error-message h3 {
           font-size: 18px;
           text-align: center;
        }
        .videopeel-error-message h4 {
           font-size: 14px;
           text-align: center;
        }
      </style>
      <div class="videopeel-error-message">
        <h3>We were unable to load the VideoPeel plugin.</h3>
        <h4>Please double check the instructions and make sure you have copied all the code over correctly.</h4>
      </div>
      `)
      return;
    }

    if (hasContainerBeenBootstrapped === IS_BOOTSTRAPPED) {
      console.warn(`VideoPeel target container has already been bootstrapped:`, container);
    }

    const isPreview = !!container.getAttribute('data-preview');
    const pluginUrl = `${config.CDN_URL}index.html`;
    const iframeSource = `${pluginUrl}?pluginId=${pluginId}&isPreview=${isPreview}${isValidUUID(organizationId) ? `&orgId=${organizationId}` : ''}`;

    container.innerHTML = minifyJsAndHTML(`
        <iframe
          id="vpFrame-${pluginId}"
          width="100%"
          height="550px"
          allowfullscreen
          name="pluginFrame"
          border="0"
          allowtransparency="true"
          cellspacing="0"
          scrolling="yes"
          src="${iframeSource}"
          style="overflow-y: hidden; border: none; background: transparent;"
        >
        </iframe>
      `);

    container.setAttribute('data-vp-status', IS_BOOTSTRAPPED);
  });

}

const loadCompleted = document.readyState === 'complete' ||
  (document.readyState !== 'loading' && !document.documentElement.doScroll);

if (loadCompleted) {
  injectCarouselIframe();
} else {
  document.addEventListener("DOMContentLoaded", injectCarouselIframe);
}
